import LoremIpsum from "common/frontend/components/LoremIpsum"
import * as React from "react"

export interface HelpPage {
    name: string
    path: string
    parts: {
        name: string
        content: React.ReactNode
    }[]
}

export const pages: HelpPage[] = [
    {
        name: 'Getting started',
        path: '/help',
        parts: []
    },
    {
        name: 'Dashboard',
        path: '/help/dashboard',
        parts: [
            {
                name: 'Ceremony template',
                content: <LoremIpsum count={3} units={'paragraphs'} />
            },
            {
                name: 'Ceremony timeline',
                content: <LoremIpsum count={3} units={'paragraphs'} />
            },
            {
                name: 'Tracking your progress',
                content: (
                    <>
                        <LoremIpsum count={3} units={'paragraphs'} />
                        <p>Let's have content about Kittens</p>
                    </>
                )
            },
            {
                name: 'Previewing media',
                content: (
                    <p>
                        Nick Sellen &larr; for indexer to find another Kitten!
                    </p>
                )
            }
        ]
    },
    {
        name: 'Music selection',
        path: '/help/music',
        parts: []
    },
    {
        name: 'Visual selection',
        path: '/help/visual',
        parts: []
    },
    {
        name: 'Review',
        path: '/help/review',
        parts: []
    }
]