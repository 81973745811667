import GeneralError from 'common/frontend/components/GeneralError'
import * as React from 'react'
import { Suspense, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { RouterProvider } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import router, { searchableRoutes } from './router'

import { AudioProvider } from 'common/frontend/audio'
import AppLoader from 'common/frontend/components/AppLoader'
import SearchableRouteProvider from 'common/frontend/components/search/SearchableRouteProvider'
import { LastSavedProvider, SetupEventSync } from 'common/frontend/state'
import { Slide, toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { VisualComponentUploadProvider } from 'common/frontend/components/visualComponentUpload'

function HandleUnhandledErrors({ children }) {
    useEffect(() => {
        const handler = (event: PromiseRejectionEvent) => {
            event.preventDefault()
            toast('Oops, something went wrong')
            console.error(event.reason)
        }
        window.addEventListener('unhandledrejection', handler)
        return () => {
            window.removeEventListener('unhandledrejection', handler)
        }
    }, [])
    return children
}

export function App() {
    return (
        <LastSavedProvider>
            <AudioProvider>
                <RecoilRoot>
                    <ErrorBoundary
                        FallbackComponent={GeneralError}
                    >
                        <Suspense
                            fallback={(
                                <AppLoader />
                            )}
                        >
                            <HandleUnhandledErrors>
                                <VisualComponentUploadProvider>
                                <SetupEventSync />
                                <SearchableRouteProvider routes={searchableRoutes} />
                                <RouterProvider router={router} />
                                </VisualComponentUploadProvider>
                            </HandleUnhandledErrors>
                            <ToastContainer
                                position="top-center"
                                hideProgressBar
                                theme='dark'
                                transition={Slide}
                            />
                        </Suspense>
                    </ErrorBoundary>
                </RecoilRoot>
            </AudioProvider>
        </LastSavedProvider>
    )
}
