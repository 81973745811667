import FoldableSection from 'common/frontend/components/FoldableSection'
import SearchableContent from 'common/frontend/components/search/SearchableContent'
import { usePageTitle } from 'common/frontend/hooks'
import { getBemClasses } from 'common/frontend/utils'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { HelpPage, pages } from '../help'

interface HelpPageProps {
    title: string
    page: HelpPage
}

export default function HelpPage({ title, page }: HelpPageProps) {
    usePageTitle('Help', title)
    const selectedPath = page.path
    const selectedPage = pages.find(page => page.path === selectedPath)
    return (
        <div className="help-page">
            <section className="full-image full-image--hands">
                <div className="full-image__content">
                    <SearchableContent>
                        <h1>Plan your perfect day</h1>
                        <p>Welcome to the Wesley Events portal which will enable you to build the perfect blend of sounds and images for your big day.</p>
                    </SearchableContent>
                    <Link to="/login">
                        <button className="btn btn__lg">Login</button>
                    </Link>
                </div>
            </section>
            <section className="container-padding">
                <div className="cols">
                    <div>
                        <h3>Wesley Events help centre</h3>
                        <p>This page provides instructions and support on how to use our platform. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam.</p>
                    </div>
                </div>
                <div className="cols cols--spaced">
                    <div className="col-3">
                        <div className="rounded-menu">
                            {pages.map(page => (
                                <Link
                                    key={page.name}
                                    to={page.path}
                                    className={getBemClasses('rounded-menu__entry', { selected: page.path === selectedPath })}
                                >
                                    {page.name}
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className="col-grow">
                        <SearchableContent>
                            <h3 className="help-page__title">
                                {selectedPage.name}
                            </h3>
                            {selectedPage && (
                                selectedPage.parts.map(part => (
                                    <FoldableSection
                                        key={part.name}
                                        title={part.name}
                                        defaultClosed
                                    >
                                        {part.content}
                                    </FoldableSection>
                                ))
                            )}
                        </SearchableContent>
                    </div>
                </div>
            </section>
        </div>
    )
}