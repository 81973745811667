import { Tab } from '@headlessui/react'
import axios from 'axios'
import { SearchTrackInfo } from 'common/types/commonTypes'
import { debounce, isEmpty, last, map } from 'lodash'
import * as React from 'react'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useAsyncEffect } from 'use-async-effect'
import { Form } from 'common/frontend/components/Form'
import Cover from '../components/Cover'
import TrackBox from '../components/TrackBox'
import { useMusicSearchResults } from '../state'
import { MusicSearchButtons } from '../components/MusicSearchButtons'

const schema = {
    type: "object",
    properties: {
        query: {
            type: "string",
        }
    }
}

const uiSchema = {
    query: {
        "ui:label": false,
        "ui:placeholder": "Search music",
        "ui:autofocus": true,
        "ui:options": {hideTitle: true},
        classNames: "search-input"
    }
}


export function MusicLibraryPublicPage() {
    const [queryFormData, setQueryFormData] = useState({ query: '' })
    const [query, setQuery] = useState('')

    const debouncedSetQuery = useCallback(debounce(setQuery, 300), [])

    useEffect(() => {
        debouncedSetQuery(queryFormData.query)
    }, [queryFormData])

    return <div className="music-library-public">
        <div className="music-library-public__content">
            <Form
                theme="wesley"
                jsonSchema={schema}
                uiSchema={uiSchema}
                value={queryFormData}
                onChange={data => setQueryFormData(data.formData)}
                children={[]}
            />
            <Tab.Group as="div" className="tabs rounded-box relative">
                <Tab.List className="tabs__tabs">
                    <Tab as="div">Results</Tab>
                </Tab.List>
                <Tab.Panels className="tabs__panels">
                    <ErrorBoundary FallbackComponent={({ error }) => <div>error: {error.message}</div>}>
                        <Suspense fallback={<Cover center translucent loading />}>
                            <Tab.Panel className="pad music-library-public__content__results">
                                <MusicLibraryPublicResults query={query} />
                            </Tab.Panel>
                        </Suspense>
                    </ErrorBoundary>
                </Tab.Panels>
            </Tab.Group>
        </div>
    </div>
}

function MusicLibraryPublicResults(props: { query }) {
    const { query } = props;
    const {nextPage, prevPage, hasNext, hasPrev, loadingResults, allResults, results} = useMusicSearchResults({query, isPublic: true})
   
    return <>
        <div className="music-library-public__content__results__tracks">
            {
                !loadingResults && query && allResults?.length === 0 ?
                    <div>No results found</div>
                    :
                    map(results, (track) => <TrackBox track={track} id={track.id} />)
            }
        </div>
        <MusicSearchButtons {...{hasNext, hasPrev, nextPage, prevPage}}/>
        <Cover enabled={loadingResults} center translucent loading />
    </>
}