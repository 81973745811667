import { useAction } from 'common/frontend/actions'
import { useConfig } from 'common/frontend/state'
import { EventServiceSubscriber, SaveEventUsersParams } from 'common/types/eventService'
import { resultOrThrow } from 'common/universal/universalUtils'

export function useSaveUsersAction() {
    const config = useConfig()
    return useAction(async (params: SaveEventUsersParams) => {
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        return await resultOrThrow(subscriber.control.saveUsers(params))
    })
}