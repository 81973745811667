import { formatDate, formatTime } from 'common/frontend/formatters'
import { useEvent } from 'common/frontend/state'
import { eventStatusLabel } from 'common/frontend/utils'
import * as React from 'react'
import { ReactNode } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './ViewAsServiceUserLayout.scss'
import LastSaved from 'common/frontend/pages/LastSaved'
import Action from 'common/frontend/components/Action'

interface EventHeadingProps {
    label: string
    children: React.ReactNode
}

function EventHeading ({ label, children }: EventHeadingProps) {
    return (
        <div className="event-heading">
            <div className="event-heading__label">{label}</div>
            <div>{children}</div>
        </div>
    )
}

export default function ViewAsServiceUserLayout({ children }: { children: ReactNode }) {
    const event = useEvent()
    const navigate = useNavigate()
    return (
        <>
            {event && (
                <div className="view-as-user-header">
                     <div style={{ display: "flex" , alignItems: "center", gap: 10 }}>
                        <Action icon='Home' size={30} onClick={() => navigate(`/landing`)}/>
                        <div>
                            <EventHeading label="Event name">
                                <Link
                                    to={`/events/${event.id}/view`}
                                    style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold' }}
                                >
                                    {event.name}
                                </Link>
                            </EventHeading>
                            <LastSaved />
                        </div>
                    </div>
                    {/* <LastSaved />
                    <EventHeading label="Event name">
                        <Link
                            to={`/events/${event.id}/view`}
                            style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold' }}
                        >
                            {event.name}
                        </Link>
                    </EventHeading> */}
                    <EventHeading label="Booking ID">
                        <span className="nowrap">{event.bookingId}</span>
                    </EventHeading>
                    {event.date && (
                        <EventHeading label="Date">
                            {formatDate(event.date)}
                        </EventHeading>
                    )}
                    {event.date && (
                        <EventHeading label="Time">
                            {formatTime(event.date)}
                        </EventHeading>
                    )}
                    {event.room && (
                        <EventHeading label="Room">
                            {event.room.name}
                        </EventHeading>
                    )}
                    {event.venue && (
                        <EventHeading label="Venue">
                            {event.venue.name}
                        </EventHeading>
                    )}
                </div>
            )}
            {children}
        </>
    )
}