import { RouteObject } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { searchableRoutesState } from './Search'

export interface SearchableRouteProviderProps {
    routes: RouteObject[]
}

export default function SearchableRouteProvider({ routes }: SearchableRouteProviderProps) {
    const set = useSetRecoilState(searchableRoutesState)
    set(routes)
    return null
}