import Cover from 'common/frontend/components/Cover'
import { useSetEventId } from 'common/frontend/state'
import * as React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useEventList } from '../state'

export default function SelectEventPage() {
    const navigate = useNavigate()
    const events = useEventList()
    const setEventId = useSetEventId()

    function selectEvent(id: string) {
        setEventId(id)
        navigate('/landing')
    }

    if (events === undefined) {
        return (
            <Cover center loading />
        )
    }

    if (events.length === 1) {
        setEventId(events[0].id)
        return <Navigate to="/landing" />
    }

    return (
        <div
            className="rounded-box pad"
            style={{ maxWidth: 400, margin: '80px auto', padding: 20, textAlign: 'center' }}
        >
            {events.length === 0 && (
                <h2 style={{ fontWeight: 500 }}>Sorry, there are no events</h2>
            )}
            {events.length > 1 && <>
                <h4>Select an event</h4>
                {events.map(event => (
                    <div
                        key={event.id}
                        className="rounded-box pad"
                        style={{ marginBottom: 10, cursor: 'pointer' }}
                        onClick={() => selectEvent(event.id)}
                    >
                        {event.name}
                    </div>
                ))}
            </>}
        </div>
    )
}