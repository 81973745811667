import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// TODO: these should go into json files probably
const resources = {
    en: {
        translation: {
            "example translation": "Woooooah yeah!"
        }
    },
    fr: {
        translation: {
            "example translation": "Bonjour!"
        }
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'fr',
        interpolation: {
            escapeValue: false // not needed for react
        }
    })

export default i18n