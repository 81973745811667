import { EventUserInfo, SimpleEvents } from 'common/types/eventService'
import * as React from 'react'

export interface EventUserProps {
    user: EventUserInfo
    onRemove?: () => void
}

export default function EventUser({ user, onRemove }: EventUserProps) {
    return (
        <div
            className="info-box"
        >
            <div>
                <div><strong>{user.firstName} {user.lastName}</strong></div>
                <div>{user.email}</div>
            </div>
            {onRemove && (
                <button
                    className="action action--trash"
                    onClick={() => onRemove()}
                />
            )}
        </div>
    )
}