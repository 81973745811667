import { useResetAudio } from 'common/frontend/audio'
import DevBar from 'common/frontend/components/dev/DevBar'
import Footer from 'common/frontend/components/Footer'
import Header from 'common/frontend/components/Header'
import { SearchQuery } from 'common/frontend/components/search/SearchQuery'
import { useQueryParams } from 'common/frontend/hooks'
import * as React from 'react'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

export default function MainLayout() {
    const {headless} = useQueryParams()
    const resetAudio = useResetAudio()
    const location = useLocation()

    useEffect(() => {
        resetAudio()
    }, [location])

    return (
        <div className="container">
            { !headless && <Header showLogin /> }
            <div className="sticky-wrapper">
                <div className="sticky-wrapper__inner">
                    <SearchQuery />
                </div>
            </div>
            <Outlet />
            { !headless && <Footer /> }
            <DevBar />
        </div>
    )
}