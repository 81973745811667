import * as plusImage from "common/assets/plus.png"
import EventUser from 'common/frontend/components/EventUser'
import EventUserAdd from 'common/frontend/components/EventUserAdd'
import Modal from 'common/frontend/components/Modal'
import ProgressSteps from 'common/frontend/components/ProgressSteps'
import { usePageTitle } from 'common/frontend/hooks'
import { useEventId } from 'common/frontend/state'
import { wesleyDebugNamespace } from 'common/frontend/utils'
import { EventUserInfo } from 'common/types/eventService'
import { sortBy } from 'lodash'
import * as React from 'react'
import { useState } from 'react'
import { useSaveUsersAction } from '../actions'
import { useEventUserInfosState } from '../state'

const debug = wesleyDebugNamespace.extend('actions')

export default function EventUsersPage() {
    usePageTitle('Users')
    return (
        <div className="streaming-page">
            <section className="progress-navigation">
                <ProgressSteps headerOnly />
            </section>
            <section className="section--grey container-padding">
                <EventUsers />
            </section>
        </div>
    )

}

export function EventUsers() {
    const eventId = useEventId()
    const [saveUsers, saveUsersIsPending] = useSaveUsersAction()
    const [eventUserInfos, setEventUserInfos] = useEventUserInfosState(eventId)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    function openModal() {
        setModalIsOpen(true)
    }

    function closeModal() {
        setModalIsOpen(false)
    }

    async function onAdd(newUsers: EventUserInfo[]) {
        const { eventUserInfos: updatedEventUserInfos } = await saveUsers({
            eventId,
            eventUserInfos: newUsers,
        })
        setEventUserInfos(updatedEventUserInfos)
        setModalIsOpen(false)
    }

    async function onRemove(id: string) {
        // Optimistic update
        setEventUserInfos(eventUserInfos => eventUserInfos.filter(entry => entry.id !== id))
        const { eventUserInfos: updatedEventUserInfos } = await saveUsers({
            eventId,
            removeEventUsersById: [id]
        })
        setEventUserInfos(updatedEventUserInfos)
    }

    return (
        <div className="manage-users">
            <h2>Invite users</h2>
            <p>It’s easy to invite additional people to the flow in order to add and edit media. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.</p>
            <div className="rounded-box pad">
                <h4>Users</h4>
                <div className="info-box-list">
                    {sortBy(eventUserInfos, 'email').map(eventUserInfo => (
                        <EventUser
                            key={eventUserInfo.id}
                            user={eventUserInfo}
                            onRemove={() => onRemove(eventUserInfo.id)}
                        />
                    ))}
                </div>
                <button
                    className="manage-users__add rounded-box cols cols--center"
                    onClick={() => openModal()}
                >
                    <div>Add users</div>
                    <img
                        style={{ marginLeft: 'auto' }}
                        src={plusImage}
                    />
                </button>
            </div>
            <Modal
                opened={modalIsOpen}
                onClose={() => closeModal()}
                isPending={saveUsersIsPending}
                closeOnClickOutside={false}
                closeOnEscape={false}
            >
                <EventUserAdd
                    existingUserEmails={eventUserInfos?.map(eventUserInfo => eventUserInfo.email)}
                    onSave={newUser => onAdd([newUser])}
                    onDone={() => closeModal()}
                    addButtonText="Invite user"
                />
            </Modal>
        </div>
    )
}