import { configState, isConnectedState, isLoggedInState } from 'common/frontend/state'
import { wesleyDebugNamespace } from 'common/frontend/utils'
import { EventInfo, EventServiceSubscriber, EventUserInfo } from 'common/types/eventService'
import { resultOrThrow } from 'common/universal/universalUtils'
import { sortBy } from 'lodash'
import { atomFamily, selector, useRecoilState, useRecoilValue } from 'recoil'

const debug = wesleyDebugNamespace.extend('state')

const eventListState = selector<EventInfo[]>({
    key: 'Events',
    get: async ({ get }) => {
        const config = get(configState)
        const isLoggedIn = get(isLoggedInState)
        const isConnected = get(isConnectedState)
        if (!isLoggedIn || !isConnected || !config) return
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        debug('getting events')
        const { events } = await resultOrThrow(subscriber.control.getEvents({}))
        return sortBy(events, 'name')
    }
})

export function useEventList() {
    return useRecoilValue(eventListState)
}

const eventUserInfosState = atomFamily<EventUserInfo[], string>({
    key: 'EventUserInfos',
    effects: eventId => [
        ({ setSelf, getPromise }) => {
            setSelf(fetch())
            async function fetch() {
                const config = await getPromise(configState)
                const isLoggedIn = await getPromise(isLoggedInState)
                const isConnected = await getPromise(isConnectedState)
                if (!isLoggedIn || !isConnected || !config) return
                const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                const { eventUserInfos } = await resultOrThrow(subscriber.control.getUsers({ eventId }))
                return eventUserInfos
            }
        }
    ]
})

export function useEventUserInfosState(eventId: string) {
    return useRecoilState(eventUserInfosState(eventId))
}