import GeneralError from 'common/frontend/components/GeneralError'
import ChangePasswordPage from 'common/frontend/pages/ChangePasswordPage'
import EventUsersPage from './pages/EventUsersPage'
import LoggingOut from 'common/frontend/pages/LoggingOut'
import ResetPasswordPage from 'common/frontend/pages/ResetPasswordPage'
import SimpleMessagePage from 'common/frontend/pages/SimpleMessagePage'
import { eventRoutes } from 'common/frontend/routes'
import { isLoggedInState, useEventId, useSetEventId } from 'common/frontend/state'
import StoriesPage from 'common/frontend/stories/StoriesPage'
import * as React from 'react'
import { createBrowserRouter, Navigate, Outlet, RouteObject, useRouteError } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import AccountPage from 'common/frontend/pages/AccountPage'
import RequestPasswordResetPage from 'common/frontend/pages/RequestPasswordResetPage'
import { pages as helpPages } from './help'
import MainLayout from './layouts/MainLayout'
import AcceptInvitePage from 'common/frontend/pages/AcceptInvitePage'
import HelpPage from './pages/HelpPage'
import LoginPage from './pages/LoginPage'
import SelectEventPage from './pages/SelectEventPage'
import TermsAndConditionsPage from './pages/TermsAndConditionsPage'
import { useEventList } from './state'
import {MusicLibraryPublicPage} from "common/frontend/pages/MusicLibraryPublicPage"
import ViewAsServiceUserLayout from './pages/ViewAsServiceUserLayout'
function RedirectIfLoggedOut({ children }) {
    const isLoggedIn = useRecoilValue(isLoggedInState)
    if (!isLoggedIn) {
        console.log('not logged in redirecting to login!')
        return <Navigate to="/login" replace />
    }
    return children
}

function RedirectIfLoggedIn({ children }) {
    const isLoggedIn = useRecoilValue(isLoggedInState)
    if (isLoggedIn) {
        return <Navigate to="/choose" replace />
    }
    return children
}

function MaybeChooseEvent({ children }) {
    const isLoggedIn = useRecoilValue(isLoggedInState)
    const events = useEventList()
    const eventId = useEventId()
    const setEventId = useSetEventId()
    if (isLoggedIn && !eventId) {
        if (events?.length === 1) {
            setEventId(events[0].id)
        } else {
            return <Navigate to="/choose" replace />
        }
    }
    return children
}

function RouterError() {
    const error = useRouteError()
    return <GeneralError error={error} />
}

export const searchableRoutes: RouteObject[] = [
    {
        path: 'terms',
        element: <TermsAndConditionsPage title='Terms & Conditions' />
    },
    ...helpPages.map(page => {
        return {
            path: page.path,
            element: (
                <HelpPage
                    title={page.name}
                    page={page}
                />
            )
        }
    })
]

export default createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        errorElement: <RouterError />,
        children: [
            {
                path: "music-library",
                element: <MusicLibraryPublicPage />
            },  
            {
                path: 'logout',
                element: <LoggingOut />
            },
            {
                // logged out only pages

                path: '',
                element: (
                    <RedirectIfLoggedIn>
                        <Outlet />
                    </RedirectIfLoggedIn>
                ),
                children: [
                    {
                        path: '',
                        element: <Navigate to="login" />
                    },
                    {
                        path: 'login',
                        element: <LoginPage />
                    },
                    {
                        path: 'invite',
                        element: <AcceptInvitePage />
                    },
                    {
                        path: 'forgot-password',
                        element: <RequestPasswordResetPage />
                    },
                ]
            },
            {
                // logged in only pages

                path: '',
                element: (
                    <RedirectIfLoggedOut>
                        <Outlet />
                    </RedirectIfLoggedOut>
                ),
                children: [
                    {
                        path: 'choose',
                        element: <SelectEventPage />
                    },
                    {
                        path: 'account',
                        element: <AccountPage />
                    },
                    {
                        path: 'change-password',
                        element: <ChangePasswordPage />
                    },
                    {
                        path: '',
                        element: (
                            <MaybeChooseEvent>
                                <ViewAsServiceUserLayout>
                                    <Outlet />
                                </ViewAsServiceUserLayout>
                            </MaybeChooseEvent>
                        ),
                        children: [
                            ...eventRoutes,
                            {
                                path: 'users',
                                element: <EventUsersPage />
                            }
                        ]
                    }
                ]
            },

            // can be logged out or logged in

            {
                path: 'reset-password',
                element: <ResetPasswordPage />
            },

            // fall back
            {
                path: '*',
                element: <SimpleMessagePage message="Page not found" />
            }
        ]
    },

    // dev only pages
    ...(process.env.NODE_ENV === 'development' ? [
        {
            path: 'stories',
            element: <StoriesPage />
        }
    ] : []),
])