import * as React from 'react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { highlightedElementsState, searchQueryState } from './Search'

function isElementInView(el: HTMLElement) {
    const rect = el.getBoundingClientRect()
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

function removeLeadingSlash(str) {
    if (!str) return str
    return str.replace(/^\//, '')
}

export function SearchQuery() {
    const query = useRecoilValue(searchQueryState)
    const resetQuery = useResetRecoilState(searchQueryState)
    const highlightElements = useRecoilValue(highlightedElementsState)
    const [selectedElement, setSelectedElement] = useState<HTMLElement | null>(null)
    const location = useLocation()

    useEffect(() => {
        // when we navigate away clear everything out
        // (only supports when entry.path exactly matches location.pathname...)
        if (query.result && removeLeadingSlash(query.result.entry.path) !== removeLeadingSlash(location.pathname)) {
            resetQuery()
        }
    }, [location])

    useEffect(() => {
        if (highlightElements.length === 0) {
            setSelectedElement(null)
            return
        }
        setSelectedElement(highlightElements[0])
    }, [highlightElements])

    useEffect(() => {
        if (!selectedElement) return
        if (!isElementInView(selectedElement)) {
            // requestAnimationFrame was needed only if we're already on the page
            // not entirely sure why though
            requestAnimationFrame(() => {
                selectedElement.scrollIntoView()
            })
        }
        selectedElement.classList.add('search-highlight--selected')
        return () => {
            selectedElement.classList.remove('search-highlight--selected')
        }
    }, [selectedElement])

    const selectedElementIndex = selectedElement ? highlightElements.indexOf(selectedElement) : -1

    function clear() {
        resetQuery()
    }

    function next() {
        if (!selectedElement) return
        const idx = highlightElements.indexOf(selectedElement)
        if (idx === -1) return
        if (highlightElements[idx + 1]) {
            setSelectedElement(highlightElements[idx + 1])
        } else if (idx !== 0) {
            setSelectedElement(highlightElements[0])
        }
    }

    if (!query.queryString || highlightElements.length === 0) return null

    return (
        <div className="search-query">
            <span className="search-query__query">{query.queryString}</span>
            {highlightElements.length > 1 && (
                <>
                    <button
                        className="action action--next"
                        onClick={() => next()}
                    />
                    <span className="search-query__number numeric">{selectedElementIndex + 1} / {highlightElements.length}</span>
                </>
            )}
            {/* <button
                className="action action--search"
                onClick={() => setSearchIsOpen(true)}
            /> */}
            <button
                className="action action--remove"
                onClick={() => clear()}
            />
        </div>
    )
}