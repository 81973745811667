import { useLoginAction } from 'common/frontend/actions'
import AuthSection from 'common/frontend/components/AuthSection'
import Cover from 'common/frontend/components/Cover'
import * as React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'common/frontend/components/Form'
import { defaultBackgroundImages } from '../../../../common/frontend/images'
import { UiSchema } from '@rjsf/utils'

const schema = {
    type: 'object',
    required: [
        'email',
        'password'
    ],
    properties: {
        email: {
            type: "string",
            format: "email",
            title: "Email"
        },
        password: {
            type: "string",
            title: "Password"
        }
    }
}

const uiSchema: UiSchema = {
    email: {
        "ui:autocomplete": "email"
    },
    password: {
        "ui:widget": "password",
        "ui:autocomplete": 'current-password',
        "ui:help": <div>Forgot your password? <Link to="/forgot-password">Reset here</Link></div>
    }
}

const initialData = {
    email: '',
    password: '',
}

function LoginForm() {
    const [data, setData] = useState(initialData)
    const [login, isPending] = useLoginAction()
    return (
        <>
            <Cover center translucent loading enabled={isPending} />
            <h1>Sign in</h1>
            <Form
                theme="wesley-auth"
                jsonSchema={schema}
                uiSchema={uiSchema}
                value={data}
                onChange={data => setData(data.formData)}
            >
                <button
                    type="submit"
                    className="btn"
                    onClick={() => login(data)}
                >
                    Log in
                </button>
                {/* <p className="emphasis">Don't have an account? <Link to="/signup">Sign up now</Link></p> */}
            </Form>
        </>
    )
}

export default function LoginPage() {
    return (
        <AuthSection images={defaultBackgroundImages}>
            <LoginForm />
        </AuthSection>
    )
}